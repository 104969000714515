import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
//import ArrowRight from "../images/arrow-right.svg";

export const GlobalStyle = createGlobalStyle`
  ${reset}
  
  *, *:before, *:after {
    box-sizing: border-box;
  }
  html {
    box-sizing: border-box;
    transition: .3s all ease-in-out;
    //scroll-behavior: smooth;
  }
  body {
    line-height: 1.5em;
    letter-spacing: 0;
    font-family: ${({ theme }) => theme.font.body};
    font-size: ${({ theme }) => theme.fontSize.lg};
    color: ${({ theme }) => theme.colors.greyMedium};
    overflow-wrap: break-word;
    
    #gatsby-focus-wrapper {
      display: flex;
      min-height: 100vh;
      flex-direction: column;
    }
  }

  main {
    flex: 1;
  }
  a {
    color: inherit;
    text-decoration: none;
    font-family: inherit;
  }
  h1 {
    font-size: 34px;
    @media (min-width: ${({theme}) => theme.breakpoints.s}) {
      font-size: 42px;
    }
  }
  h2 {
    font-size: 27px;
    @media (min-width: ${({theme}) => theme.breakpoints.s}) {
      font-size: 34px;
    }
  }
  h3 {
    font-size: 25px;
    @media (min-width: ${({theme}) => theme.breakpoints.s}) {
      font-size: 30px;
    }
  }
  h4 {
    font-size: 23px;
    @media (min-width: ${({theme}) => theme.breakpoints.s}) {
      font-size: 27px;
    }
  }
  h5 {
    font-size: 22px;
    @media (min-width: ${({theme}) => theme.breakpoints.s}) {
      font-size: 25px;
    }
  }
  h6 {
    font-size: 20px;
    @media (min-width: ${({theme}) => theme.breakpoints.s}) {
      font-size: 22px;
    }
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    line-height: 1.35em;
    margin-top: 0;
    margin-bottom: .5em;
    font-family: ${({ theme }) => theme.font.heading};
  }
  p {
    color: #878787;
    margin-bottom: .4em;
  }
  .gatsby-image-wrapper {
    width: 100%;
  }

  /* answer styles */
  
  button#er_search_voice {
    display: none;
  }
  

`