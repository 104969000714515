
const theme = {
  font: {
    heading: 'Nunito, Tahoma, Geneva, Verdana, sans-serif',
    body: 'Montserrat, Verdana, sans-serif',
  },
  fontSize: {
    xs: '0.75rem',
    sm: '0.875rem',
    base: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '4rem',
  },
  colors: {
    primary: '#b710a1',
    primaryLight: '#e456e9',
    primaryDark: '#3d0435',
    bg: '#fff',
    text: '#021212',
    textInvert: '#fff',
    greyExtraLight: '#a9adb0',
    greyLight: '#909599',
    greyMedium: '#575759',
    greyDark: '#515254',
    blueGrey: '#adc1cc',
    whyOrange: '#F28516',
    whyMagenta: '#B62462',
    whyBlue: '#0E4D80',
    whyGreen: '#8D9140',
    whyYellow: '#F5B906',
    whyCyan: '#799fb8',
    darkMagenta: '#d94b7e',
    darkYellow: '#dea500',
    darkBlue: '#3b6ea4',
    darkGreen: '#acb260',
    darkOrange: '#FFA23A'
  },
  breakpoints: {
    xxs: '400px',
    xs: '650px',
    s: '768px',
    m: '898px',
    l: '1024px',
    xl: '1200px',
  },
}

export default theme