import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Icon from '../elements/Icon'
import styled from 'styled-components'

const MenuWrapper = styled.div`
  flex: 1;
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    display: block;
  }
  svg {
    width: 47px;
    height: 47px;
    color: ${({ theme }) => theme.colors.greyLight};
    transition: color 0.2s ease;
    :hover {
      color: ${({ theme }) => theme.colors.whyMagenta};
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      width: 43px;
      height: 43px;
    }
  }
  path {
    stroke: ${({ theme }) => theme.colors.greyLight};
    transition: stroke 0.2s ease;
    :hover {
      stroke: ${({ theme }) => theme.colors.whyMagenta};
    }
  }
  span {
    cursor: pointer;
    transition: all 1s ease;
  }
  svg.close {
    width: 40px;
    height: 40px;
  }
`
const Menu = ({ open, setOpen }) => {
  return (
    <MenuWrapper>
      <span onClick={() => setOpen(!open)}>
        {open ? <Icon name="Close" /> : <Icon name="Menu" />}
      </span>
    </MenuWrapper>
  )
}

Menu.propTypes = {}

export default Menu
