import React from 'react'
import {
  GrMail,
  GrFacebookOption,
  GrTwitter,
  GrInstagram,
  GrClose
} from 'react-icons/gr'

import { FiMenu, FiChevronRight, FiChevronDown } from "react-icons/fi";
import PropTypes from 'prop-types'


const Icon = ({ name }) => {
  const renderIcon = () => {
    switch (name) {
      case 'Facebook':
        return (
          <GrFacebookOption />
        )
      case 'Twitter':
        return (
          <GrTwitter />
        )
      case 'Instagram':
        return (
          <GrInstagram />
        )
      case 'Mail':
        return (
          <GrMail />
        )
      case 'Menu':
        return (
          <FiMenu />
        )
      case 'Close':
        return (
          <GrClose className="close"/>
        )
      case 'Right':
        return (
          <FiChevronRight />
        )
      case 'Down':
        return (
          <FiChevronDown />
        )
      default:
        return null
    }
  }

  return (
    <>
      {renderIcon()}
    </>
  )
}

Icon.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  style: PropTypes.object
}

export default Icon
