import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Container from '../primary/Container'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'
import Icon from '../elements/Icon'

const Wrapper = styled.nav`
  position: fixed;
  width: fit-content;
  bottom: 0;
  transition: all 0.2s ease;
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  opacity: ${({ open }) => (open ? '1' : '0')};
  z-index: 0;
  overflow-y: auto;
  padding-bottom: 100px;
  height: fit-content;
  display: flex;
  justify-content: center;
  top: 110px;
  .container {
    background-color: white;
    max-width: fit-content;
    padding: 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    position: absolute;
    .container {
      border-radius: 20px;
    }
  }
`

const Menu = styled.div`
  min-width: 300px;
  width: 100%;
  font-size: 20px;
  padding: 10px 25px 20px;
  z-index: 5000;
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    display: flex;
    justify-content: space-between;
  }
  .list-item {
    margin-bottom: 0.5em;
    transition: color 0.2s ease;
  }
  .main {
    font-size: 18px;
    font-family: ${({ theme }) => theme.font.heading};
    border-bottom: 1px solid;
    font-weight: 700;
    padding-top: 0.3em;
    margin-top: 0.1em;
    margin-bottom: 1.2em;
  }
  .secondary {
    font-size: 16px;
    font-weight: 500;
    color: #6d7174;
    border-radius: 5px;
    :not(.subcat) {
      background-color: #f3f8fa;
      padding: 2px 8px;
    }
  }
  .subcat {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0.3em;
    margin-left: 1.6em;
    :last-of-type {
      margin-bottom: 0.6em;
    }
  }
  a {
    display: block;
    width: 100%;
  }
`

const MenuGroup = styled.div`
  &.answer {
    .main {
      color: ${({ theme }) => theme.colors.whyMagenta};
    }
    .cat-title {
      :hover {
        color: ${({ theme }) => theme.colors.whyMagenta};
      }
    }
    a {
      &.active {
        color: ${({ theme }) => theme.colors.whyMagenta};
      }
      :hover {
        color: ${({ theme }) => theme.colors.whyMagenta};
      }
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    width: 100%;
  }
`

const Submenu = styled.div`
  svg {
    width: 19px;
    height: 19px;
    margin-left: 10px;
    transition: transform 0.2s ease;
    transform: ${({ accordianOpen }) =>
      accordianOpen ? 'rotate(90deg)' : 'rotate(0deg)'};
  }
`
const SubmenuTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  span {
    display: block;
  }
`
const Accordian = styled.div`
  overflow: hidden;
  transition: height 0.5s ease;
  height: auto;
  max-height: ${({ accordianOpen }) => (accordianOpen ? 'auto' : '0')};
  opacity: ${({ accordianOpen }) => (!accordianOpen ? '0' : '1')};
  max-width: 220px;
  a {
    display: block;
    margin: 0 0 15px 10px;
  }
`

const AnswersMenu = ({ open, setOpen }) => {
  const [accordianOpen, setAccordianOpen] = useState([])
  const isBrowser = typeof window !== 'undefined'

  let html
  if (isBrowser) {
    html = document.querySelector('html')
  }

  useEffect(() => {
    open ? (html.style.overflow = 'hidden') : (html.style.overflow = 'visible')
  }, [open])

  const data = useStaticQuery(graphql`
    query AnswerListQuery {
      allPrismicCategory {
        nodes {
          uid
          data {
            name
            category_group
          }
        }
      }
      allPrismicSubcategory {
        nodes {
          uid
          data {
            name
            parent_category {
              uid
            }
          }
        }
      }
    }
  `)

  const categories = data.allPrismicCategory.nodes
  const subcategories = data.allPrismicSubcategory.nodes

  const categoryArray = []

  categories.forEach((cat) => {
    const subcatsArray = subcategories.filter(
      (sub) => sub.data.parent_category.uid === cat.uid
    )
    categoryArray.push({
      group: cat.data.category_group,
      category: { name: cat.data.name, uid: cat.uid },
      subcats: subcatsArray
    })
  })

  const setOpenAccordians = (accordianName) => {
    accordianOpen.includes(accordianName)
      ? setAccordianOpen([])
      : setAccordianOpen([accordianName])
  }

  const getOpenAccordians = (accordianName) => {
    return accordianOpen.includes(accordianName)
  }

  return (
    <Wrapper open={open}>
      <Container>
        <Menu>
          <MenuGroup className="answer">
            <div className="main list-item">Answers</div>
            {categoryArray?.map((cat) => {
              if (cat.group === 'Answers') {
                return (
                  <Submenu
                    accordianOpen={getOpenAccordians(cat.category.name)}
                    onClick={() => setOpenAccordians(cat.category.name)}
                    key={`${cat.group}-${cat.category.uid}`}
                  >
                    <SubmenuTitle className="secondary list-item">
                      {cat.subcats?.length ? (
                        <span className="cat-title">{cat.category.name}</span>
                      ) : (
                        <Link
                          to={`/${cat.category.uid}`}
                          onClick={() => setOpen(!open)}
                          activeClassName="active"
                        >
                          {cat.category.name}
                        </Link>
                      )}
                      {cat.subcats.length ? <Icon name="Right" /> : null}
                    </SubmenuTitle>
                    <Accordian
                      accordianOpen={getOpenAccordians(cat.category.name)}
                    >
                      {cat.subcats && (
                        <Link
                          to={`/${cat.category.uid}`}
                          activeClassName="active"
                          className="subcat secondary list-item"
                        >
                          {`All ${cat.category.name}`}
                        </Link>
                      )}
                      {cat.subcats?.map((subcat, index) => (
                        <Link
                          to={`/${subcat.uid}`}
                          key={`category-${index}`}
                          activeClassName="active"
                          className="subcat secondary list-item"
                        >
                          {subcat.data.name}
                        </Link>
                      ))}
                    </Accordian>
                  </Submenu>
                )
              } else return null
            })}
          </MenuGroup>
        </Menu>
      </Container>
    </Wrapper>
  )
}

export default AnswersMenu
