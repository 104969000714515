import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  margin: 0 auto;
  padding: 0 15px;
  @media (min-width: ${({theme}) => theme.breakpoints.xs}) {
    padding: 0 25px;
  }
  @media (min-width: ${({theme}) => theme.breakpoints.xl}) {
    max-width: ${({ width }) => width ? width : "1050px"};
  }
`

const ContentContainer = ({ children, width }) => (
  <Container className='container' width={width} >
    {children}
  </Container>
);

export default ContentContainer;