import React, { useState } from "react";
import Input from "../elements/Input"
import addToMailchimp from "gatsby-plugin-mailchimp";

const Newsletter = ({ color, placeholder }) => {
  const [email, setEmail] = useState("");
  const [result, setResult] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    addToMailchimp(email)
      .then((data) => {
        setResult(data.result);
        setMessage(data.msg);
      })
      .catch(error => {
        // Errors in here are client side
        // Mailchimp always returns a 200
      });
  };
  const handleEmailChange = (event) => {
    setEmail(event.currentTarget.value);
  };
  if (result === "success") {
    return (
      <span>
        Success! Thank you for subscribing.
      </span>
    );
  }
  if (result === "error") {
    if (message.includes("not valid")) {
      return (
        <span>
          Please enter a valid email address.
        </span>
      );
    }
    if (message.includes("already subscribed")) {
      return (
        <span>
          You've already subscribed!
        </span>
      );
    }
    return (
      <span>
        There was an error with your subscription, please try again.
      </span>
    );
  } else {
    return (
      <Input
        handleSubmit={handleSubmit}
        handleEmailChange={handleEmailChange}
        name="newsletter"
        color={color}
        text="Sign me up"
        placeholder={placeholder}
      />
    )
  }
}

export default Newsletter
