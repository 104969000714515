import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'
import Container from '../primary/Container'
import Newsletter from '../page-sections/Newsletter'
import SocialLinks from '../secondary/SocialLinks'
import { IoIosArrowDropupCircle } from 'react-icons/io'
import NewsletterBg from "../../images/newsletter-bg.svg";
import sealOne from "../../images/Ippy-sq.png"
import sealTwo from "../../images/forbes.png"
import sealThree from "../../images/NAPPA-seal-2021.png"
import sealFour from "../../images/timeoutkids.png"
import sealFive from "../../images/MCA_Logo_Gold.png"

const StyledFooter = styled.footer`
  width: 100%;
  position: relative;
  margin: 0 auto;
  max-width: 100%;
  flex-direction: column;
  display: flex;
  .scroll-top-icon {
    text-align: right;
    position: absolute;
    padding: 8px;
    right: 0;
    bottom: 0;
    cursor: pointer;
    svg {
      width: 28px;
      height: 28px;
    }
  }
  @media (min-width: ${({theme}) => theme.breakpoints.xs}) {
    display: block;
  }
`
const TopFooter = styled.div`
  background: #fff;
`
const BottomFooter = styled.div`
  background: #f7f7f7;
  padding: 20px 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .logo {
    width: 110px;
    margin: 0 auto;
  }
`

const LinkWrap = styled.div`
  padding: 10px 15px;
  max-width: 800px;
  margin: 5px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  a {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    text-decoration: none;
    padding: 0 20px;
    margin: 15px 0;
    transition: color .2s ease;
    color: ${({ theme }) => theme.colors.whyBlue};
    :not(:last-of-type) {
      border-right: 1px solid #0E4D80;
    }
    :hover {
      color: ${({ theme }) => theme.colors.whyMagenta};
    }
    :focus {
      color: ${({ theme }) => theme.colors.whyMagenta};
    }
  }
  @media (min-width: ${({theme}) => theme.breakpoints.xxs}) {
    
  }
  @media (min-width: ${({theme}) => theme.breakpoints.xs}) {
    padding: 10px 0 15px;
    a {
      padding: 0 30px;
    }
  }
`
const NewsWrap = styled.div`
  margin: 0 auto;
  min-height: 285px;
  border-radius: 20px;
  text-align: center;
  padding: 80px 20px 40px;
  margin: 80px 0 10px;
  position: relative;
  &.with-image {
    background-image: url(${NewsletterBg});
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    input {
      border: none;
      background-color: #ffe28a;
    }
  }
  &.with-color {
    input{
      border: solid 1px #909599;
      background-color: #fff;
    }
  }
  &.orange {
    h3 {
      color: ${({ theme }) => theme.colors.whyOrange};
    }
  }
  &.green {
    h3 {
      color: ${({ theme }) => theme.colors.whyGreen};
    }
  }
  &.blue {
    h3 {
      color: ${({ theme }) => theme.colors.whyBlue};
    }
  }
  &.red {
    h3 {
      color: ${({ theme }) => theme.colors.whyMagenta};
    }
  }
  &.yellow {
    h3 {
      color: ${({ theme }) => theme.colors.whyYellow};
    }
  }
  .subscribe {
    width: 135px;
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%)
  }
  .inner-wrap {
    max-width: 100%;
    margin: 0 auto;
    span {
      color: white;
    }
  }
  .title {
    margin-bottom: 40px;
    font-size: 39px;
    font-weight: 800;
    font-family: ${({ theme }) => theme.font.heading};
    line-height: 1.1em;
    color: #fff;
  }
  span {
    display: block;
    padding-top: 20px;
  }
  .input {
    width: 100%;
    margin-right: 15px;
  }
  input {
    width: 100%;
    height: 58px;
    border-radius: 8px;
  }
  button {
    width: 262px;
    font-size: 20px;
  }
  @media (min-width: ${({theme}) => theme.breakpoints.xs}) {
    padding: 105px 50px 40px;
    margin: 130px 0 30px;
    .inner-wrap {
      max-width: 90%;
    }
    .subscribe {
      width: 165px;
    }
  }
  @media (min-width: ${({theme}) => theme.breakpoints.m}) {
    .inner-wrap {
      max-width: 75%;
    }
  }
`

const Connect = styled.div`
  padding: 60px 0 40px;
  text-align: center;
  h3 {
    color: ${({ theme }) => theme.colors.whyBlue};
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 40px;
    @media (min-width: ${({theme}) => theme.breakpoints.m}) {
      font-size: 48px;
    }
  }
  .inner {
    margin: 0 auto;
    max-width: 230px;
    width: 90%;
    justify-content: space-around;
    a {
      width: 50px;
      height: 50px;
      margin: 0;
      &.twitter {
        background-color: #1DA1F2;
      }
      &:last-of-type {
        background-color: ${({ theme }) => theme.colors.whyYellow};
      }
      &.facebook {
        background-color: ${({ theme }) => theme.colors.whyBlue};
      }
      &.instagram {
        background-color: ${({ theme }) => theme.colors.whyMagenta};
      }
    }
    svg {
      width: 30px;
      height: 30px;
    }
    @media (min-width: ${({theme}) => theme.breakpoints.xs}) {
      justify-content: space-between;
      a {
        width: 63px;
        height: 63px;
      }
      svg {
        width: 35px;
        height: 35px;
      }
    }
  }
`
const Copy = styled.span`
  font-size: 13px;
  display: block;
  text-align: center;
`
const Awards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 15px;
  img {
    height: 80px;
    margin-bottom: 30px;
    &.long {
      height: auto;
      width: 150px;
    }
  }
  @media (min-width: ${({theme}) => theme.breakpoints.xxs}) {
    flex-direction: row;
    max-width: 360px;
    img {
      height: 90px;
      margin-bottom: 0;
      &.long{
        height: auto;
        width: 160px;
      }
      &.round {
        height: 90px;
        width: auto;
      }
    }
  }
`

const Footer = ({ color }) => {
  return (
    <StyledFooter>
      <TopFooter>
        <Container width="1060px">
          <NewsWrap className={color ? `with-color ${color}` : `with-image`} >
            <StaticImage
              className="subscribe"
              src="../../images/subscribe.png"
              alt="Whyzz logo"
              placeholder="none"
            />
            <div className="inner-wrap">
              <h3 className="title">Subscribe to our Newsletter</h3>
              <Newsletter color="magenta" placeholder="Email address" />
            </div>
          </NewsWrap>
          <Connect>
            <h3>Stay Connected</h3>
            <SocialLinks />
          </Connect>
        </Container>
      </TopFooter>
      <BottomFooter>
        <Awards>
          {/*<a target="blank" href="https://www.forbes.com/sites/soulaimagourani/2021/07/06/the-decline-in-democracy-demands-for-more-global-citizens/?sh=522bbc173775">
            <img src={sealTwo} />
          </a>*/}
          <img src={sealOne} />
          <img className="round" src={sealThree} />
          <img src={sealFive} />
          {/*<a target="blank" href="https://www.timeout.com/new-york-kids/things-to-do/the-best-parenting-apps-for-nyc-moms-and-dads">
            <img className="long" src={sealFour} />
          </a>*/}
        </Awards>
        <StaticImage
          className="logo"
          src="../../images/whyzz-logo.png"
          alt="Whyzz logo"
          placeholder="none"
        />
        <LinkWrap>
          <Link to="/about">About</Link>
          <a href="https://whyzz.medium.com/">Blog</a>
          <Link to="/books">Books</Link>
          <Link to="/whyzzexplorer-iphoneapp">App</Link>
          <Link to="/terms">Terms</Link>
        </LinkWrap>
        <Copy>&copy; 2021 Whyzz. All rights reserved</Copy>
        <div className="scroll-top-icon">
          <IoIosArrowDropupCircle
            onClick={() =>
              scrollTo({
                top: 0,
                behavior: 'smooth'
              })
            }
          />
        </div>
      </BottomFooter>
    </StyledFooter>
  )
}

export default Footer
