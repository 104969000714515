import React from 'react'
import styled from 'styled-components'
import { FiSearch } from 'react-icons/fi'

const StyledInput = styled.form`
  .label {
    height: 0;
    overflow: hidden;
    position: absolute;
  }
  input {
    padding: 0 20px;
    color: ${({ theme }) => theme.colors.greyMedium};
    font-size: 16px;
    @media (min-width: ${({theme}) => theme.breakpoints.xs}) {
      margin-right: 20px;
    }
    &:disabled {
      background-color: white;
    }
  }
`
const Flex = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  @media (max-width: 649px) {
    &.column {
      flex-direction: column;
      input {
        margin-bottom: 20px;
        height: 50px;
      }
      button {
        width: 100%;
        height: 50px;
      }
    }
  }
`
const SubmitButton = styled.button`
  font-weight: 800;
  font-family: ${({ theme }) => theme.font.heading};
  color: white;
  font-size: 24px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  &.green {
    background-color: ${({ theme }) => theme.colors.whyGreen};
  }
  &.blue {
    background-color: ${({ theme }) => theme.colors.whyBlue};
  }
  &.yellow {
    background-color: ${({ theme }) => theme.colors.whyYellow};
  }
  &.magenta {
    background-color: ${({ theme }) => theme.colors.whyMagenta};
  }
  &.orange {
    background-color: ${({ theme }) => theme.colors.whyOrange};
  }
  &.none {
    background-color: #fff;
    
  }
`

const Input = ({
  name,
  handleSubmit,
  color,
  text,
  handleEmailChange,
  placeholder,
  isDisabled,
}) => {
  
  return (
    <StyledInput
      name={name}
      onSubmit={handleSubmit}
      color={color}
      className="newsletter-input"
    >
      <Flex className={name === "answer bar" ? "" : "column"}>
        <div className="input">
          <label htmlFor="email" className="label">
            Email
          </label>
          <input
            disabled={isDisabled}
            name="email"
            type="email"
            required
            id="email"
            onChange={handleEmailChange}
            placeholder={placeholder}
          />
        </div>
        <SubmitButton
          className={color}
          type="submit"
        >
          {text ? text : <FiSearch />}
        </SubmitButton>
      </Flex>
    </StyledInput>
  )
}

export default Input