import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Header from './Header'
import Footer from './Footer'
import { GlobalStyle } from '../../theme/global-style'
import theme from '../../theme/theme'
import { ThemeProvider } from 'styled-components'

const Layout = ({ children, color, search }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header search={search}/>
      <main style={{ overflow: 'hidden' }}>{children}</main>
      <Footer color={color} />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
