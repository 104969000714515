import React from 'react'
//import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  margin: 0 60px 0 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    margin: 0;
    .gatsby-image-wrapper {
      max-width: 120px;
    }
  }
  p {
    color: ${({ theme }) => theme.colors.whyBlue};
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 700;
    font-family: Nunito, Tahoma, Geneva, Verdana, sans-serif;
    margin-top: -20px;
    z-index: 5;
    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      max-width: 120px;
      line-height: 1.3;
      margin-top: -8px;
      margin-left: 8px;
    }
  }
`
const Logo = () => {
  const image = '../../images/whyzz-logo.png'
  return (
    <a href="/">
      <Icon>
        <StaticImage
          className="logo"
          src={image}
          alt="Whyzz logo"
          placeholder="none"
        />
        <p>Whyzz parents raise wise kids</p>
      </Icon>
    </a>
  )
}

export default Logo
