import React from 'react'
import styled from 'styled-components'
import LunrSearchBar from '../lunr-search'

const Wrapper = styled.div`
  padding-top: 10px;
  z-index: 10;
  position: relative;
  overflow: hidden;
  display: ${({ search }) => search ? "none" : "block"};
  
  form {
    background-color: rgb(161, 57, 102);
    padding: 9px 7px;
    border-radius: 7px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  input {
    width: 90%;
    border-radius: 5px;
    border: none;
    font-size: 14px;
    font-family: ${({ theme }) => theme.font.body};
    padding: 10px 7px;
    :focus-visible {
      outline: none;
    }
  }
  button {
    margin-left: 5px;
    width: 37px;
    height: 37px;
    border-radius: 9px;
    text-align: center;
    background-color: ${({ theme }) => theme.colors.whyOrange};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border: none;
  }
  svg {
    height: 20px;
    width: 20px;
    color: #fff;
  }
`

const HeaderAnswerBar = ({ initialQuery, search }) => {
 
  return (
    <Wrapper className="search-bar" search={search}>
      <LunrSearchBar initialQuery={initialQuery} />
    </Wrapper>
  )
}

export default HeaderAnswerBar
