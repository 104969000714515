import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Container from './Container'
import Logo from '../elements/Logo'
import SocialLinks from '../secondary/SocialLinks'
import MenuButton from '../secondary/Menu'
import MenuSlideOut from '../page-sections/MenuSlideOut'
import HeaderAnswerBar from '../elements/HeaderAnswerBar'
import { Link } from 'gatsby'
import ConversationsMenu from '../page-sections/ConversationsMenu'
import AnswersMenu from '../page-sections/AnswersMenu'
import AdviceMenu from '../page-sections/AdviceMenu'

const StyledHeader = styled.header`
  z-index: 200;
  background-color: #fff;
  position: relative;
  padding: 10px 0;
  .logo {
    width: 190px;
    /* flex: 1; */
    background-color: #fff;
    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      width: 150px;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.xxs}) {
      width: 140px;
    }
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.3s all ease-in-out;
    padding: 0 20px;
  }
`
const MenuWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  overflow: visible;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.1);
`
const Cover = styled.div`
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.1);
  transition: opacity 0.2s ease;
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  opacity: ${({ open }) => (open ? '1' : '0')};
  z-index: 5;
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    top: -10px;
    display: block;
  }
`
const Button = styled.span`
  color: ${({ theme }) => theme.colors.whyBlue};
  font-size: 20px;
  font-weight: 700;
  padding: 60px 20px 0;
  font-family: Nunito, Tahoma, Geneva, Verdana, sans-serif;
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    display: none;
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ open }) => (open ? 'fit-content' : 'auto')};
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  transition: 0.3s all ease-in-out;
  z-index: 50;
  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    display: none;
  }
`

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
  
  form {
    width: 300px;
    @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
      width: 340px;
    }
  }
  .search-bar {
    display: none;
    @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
      display: ${({ search }) => search ? "none" : "block"};
    }
  }
`

const MobileSearch = styled.div`
  padding: 0 10px;
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    display: none;
  }
`

const Header = ({ search }) => {
  const [open, setOpen] = useState(false)
  const [openConv, setOpenConv] = useState(false)
  const [openAns, setOpenAns] = useState(false)
  const [openAdvice, setOpenAdvice] = useState(false)
  return (
    <StyledHeader>
      <Container width="90%" className="container">
        <MenuButton open={open} setOpen={setOpen} />
        <Logo />
        <Wrapper open={openConv}>
          <Button
            onClick={() => (
              setOpenConv(!openConv), setOpenAns(false), setOpenAdvice(false)
            )}
          >
            Conversations
          </Button>
          <ConversationsMenu open={openConv} setOpen={setOpenConv} />
        </Wrapper>
        <Wrapper open={openAns}>
          <Button
            onClick={() => (
              setOpenAns(!openAns), setOpenAdvice(false), setOpenConv(false)
            )}
          >
            Answers
          </Button>
          <AnswersMenu open={openAns} setOpen={setOpenAns} />
        </Wrapper>
        <Wrapper open={openAdvice}>
          <Button
            onClick={() => (
              setOpenAdvice(!openAdvice), setOpenAns(false), setOpenConv(false)
            )}
          >
            Advice
          </Button>
          <AdviceMenu open={openAdvice} setOpen={setOpenAdvice} />
        </Wrapper>
        <SearchWrapper search={search}>
          <SocialLinks />
          <HeaderAnswerBar search={search}/>
        </SearchWrapper>
      </Container>
      <MenuWrap>
        <MenuSlideOut open={open} setOpen={setOpen} />
      </MenuWrap>
      <Cover open={openConv} />
      <Cover open={openAns} />
      <Cover open={openAdvice} />
      <MobileSearch>
        <HeaderAnswerBar search={search}/>
      </MobileSearch>
    </StyledHeader>
  )
}

export default Header
