import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Icon from '../elements/Icon'
import styled from 'styled-components'

/**
 * @param {String} slug (ID)
 * @param {String} type (category | tag)
 * @param {String} page (fontSize will be small on listing page compared to article page)
 */

const LinkWrapper = styled.div`
  flex: 1;
  .inner {
    display: flex;
    justify-content: flex-end;
    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: 90px;
      margin: 0 0 0 auto;
    }
  }
  a {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 33px;
    width: 33px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.blueGrey};
    transition: background-color 0.2s ease;
    :hover {
      background-color: ${({ theme }) => theme.colors.whyMagenta};
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      margin-bottom: 5px;
    }
  }
  svg {
    color: white;
    display: block;
    margin: 0 auto;
    width: 19px;
    height: 19px;
  }
`
const SocialLinks = () => {
  return (
    <LinkWrapper className="social-links">
      <div className="inner">
        <a
          href="https://www.instagram.com/whyzzexplorer"
          target="_blank"
          rel="noreferrer noopener"
          aria-label="instagram"
          title="instagram"
          className="instagram"
        >
          <Icon name="Instagram" />
        </a>
        <a
          href="https://www.facebook.com/whyzzexplore"
          target="_blank"
          rel="noreferrer noopener"
          aria-label="facebook"
          title="facebook"
          className="facebook"
        >
          <Icon name="Facebook" />
        </a>
        <a
          href="https://twitter.com/whyzzexplorer"
          target="_blank"
          rel="noreferrer noopener"
          aria-label="twitter"
          title="twitter"
          className="twitter"
        >
          <Icon name="Twitter" />
        </a>
        {/*
        <Link to={`/`}>
          <Icon name="Mail" />
        </Link>
        */}
      </div>
    </LinkWrapper>
  )
}

SocialLinks.propTypes = {
  name: PropTypes.string,
  slug: PropTypes.string,
  type: PropTypes.string
}

export default SocialLinks
